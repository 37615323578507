import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAllFarmListQuery } from './allFarmListQuery';
import { getControllers } from 'api/master/getControllers';
import { insertFarmController } from 'api/master/insertFarmController';
import { IS_PRODUCTION } from 'constants/index';
import { deleteFarmController } from 'api/master/deleteFarmController';
export const FARM_CONTROLLER_QUERY_KEY = 'controllers-selectedFarm';

/**
 * 선택한 농장에서 컨트롤러 번호 목록을 가져오는 쿼리
 * @returns
 */
export const useFarmControllerQuery = (targetFarmNumber: number) => {
  // 농장 목록에서 선택한 농장에 해당하는 access를 가져온다.
  const { data: farmList } = useAllFarmListQuery();
  const targetAccess = farmList?.find(
    (farm) => farm.id === targetFarmNumber,
  )?.access;
  return useQuery({
    queryKey: [FARM_CONTROLLER_QUERY_KEY, targetFarmNumber],
    queryFn: async () => {
      if (targetAccess === undefined) return null;
      return await getControllers({
        farmNumber: targetFarmNumber,
        access: targetAccess,
      });
    },
  });
};

/**
 * 선택한 농장에 컨트롤러를 추가하는 쿼리
 */
export const useInsertFarmControllerMutation = (selectedFarm: number) => {
  // selectedFarm: 컨트롤러를 추가할 농장의 farmNumber

  //targetAccess: 컨트롤러를 추가할 농장의 서버번호
  const { data: masterFarms } = useAllFarmListQuery();
  const targetAccess = masterFarms?.find(
    (farm) => farm.id === selectedFarm,
  )?.access;
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: { newControllerNumber: number }) => {
      if (targetAccess === undefined)
        throw new Error('no selectedFarm or targetAccess');
      //targetAccess번 서버에  API 요청
      const mainResponse = await insertFarmController({
        farmNumber: selectedFarm,
        controllerNumber: payload.newControllerNumber,
        targetAccess,
      });

      if (IS_PRODUCTION && targetAccess > 0) {
        //0번서버에도 API 요청을 하여, 두 DB에 모두 추가
        await insertFarmController({
          farmNumber: selectedFarm,
          controllerNumber: payload.newControllerNumber,
          targetAccess: 0,
        });
      }
      return mainResponse;
    },
    onSuccess: () => {
      queryClient.refetchQueries([FARM_CONTROLLER_QUERY_KEY, selectedFarm]);
    },
  });
};

/**
 * 선택한 농장에서 컨트롤러를 삭제하는 쿼리
 */
export const useDeleteFarmControllerMutation = (selectedFarm: number) => {
  //targetAccess: 컨트롤러를 삭제할 농장의 서버번호
  const { data: masterFarms } = useAllFarmListQuery();
  const targetAccess = masterFarms?.find(
    (farm) => farm.id === selectedFarm,
  )?.access;
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: { controllerNumber: number }) => {
      if (targetAccess === undefined)
        throw new Error('no selectedFarm or targetAccess');
      //targetAccess번 서버에  API 요청
      const mainResponse = await deleteFarmController({
        payload: {
          farmNumber: selectedFarm,
          controllerNumber: payload.controllerNumber,
        },
        access: targetAccess,
      });

      if (IS_PRODUCTION && targetAccess > 0) {
        //0번서버에도 API 요청을 하여, 두 DB에서 모두 삭제
        await deleteFarmController({
          payload: {
            farmNumber: selectedFarm,
            controllerNumber: payload.controllerNumber,
          },
          access: 0,
        });
      }
      return mainResponse;
    },
    onSuccess: () => {
      queryClient.refetchQueries([FARM_CONTROLLER_QUERY_KEY, selectedFarm]);
    },
  });
};
