import clsx from 'clsx';
import { useTargetRelayQuery } from 'hooks/reactQuery/master/targetRelay';
import useTranslate from 'hooks/useTranslate';
import { Access, RelayType } from 'types/types';
import RelaySelect from '../RelaySelect';

import { useUpdateRelayTypeMutation } from 'hooks/reactQuery/relayQuery';
import { useDeleteFarmControllerMutation } from 'hooks/reactQuery/master/farmControllerQuery';

type Props = {
  farmId: number;
  controllerNumber: number;
  access: Access;
};
export default function FarmEditRelay({
  farmId,
  controllerNumber,
  access,
}: Props) {
  const t = useTranslate();

  const { data: relays } = useTargetRelayQuery({
    farmNumber: farmId,
    controllerNumber,
    access: access,
  });

  const { mutateAsync: updateRelayType } = useUpdateRelayTypeMutation(
    farmId,
    controllerNumber,
  );
  const { mutateAsync: deleteFarmController } =
    useDeleteFarmControllerMutation(farmId);
  const [relay1, relay2, relay3, relay4, relay5, relay6] = Array.from(
    { length: 6 },
    (_, i) => relays?.find((relay) => relay.relayNumber === i + 1),
  );
  const updateRelayTypeHandler = async (
    relayNumber: number,
    relayType: RelayType,
  ) => {
    await updateRelayType({
      payload: { farmNumber: farmId, controllerNumber, relayNumber, relayType },
      access,
    });
  };

  const handleDeleteFarmController = async () => {
    //confirm
    if (!window.confirm(t('컨트롤러를 삭제하시겠습니까?'))) return;
    await deleteFarmController({ controllerNumber });
  };
  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex justify-between items-center gap-x-2.5">
        <h2 className="text-lg font-bold">{`컨트롤러${controllerNumber}: 환경제어장치`}</h2>
        <button className="text-red-500" onClick={handleDeleteFarmController}>
          삭제
        </button>
      </div>
      <div className="grid grid-cols-2 gap-x-30px gap-y-1 max-[800px]:flex-col max-[800px]:flex">
        <div className="flex items-center gap-x-0.5">
          <label htmlFor="relay-id" className={labelStyle}>
            장치1
          </label>
          <RelaySelect
            selectedRelay={relay1?.relayType}
            onSelect={async (newRelayType) => {
              await updateRelayTypeHandler(1, newRelayType);
            }}
          />
        </div>
        <div className="flex items-center gap-x-0.5">
          <label htmlFor="relay-id" className={labelStyle}>
            장치2
          </label>
          <RelaySelect
            selectedRelay={relay2?.relayType}
            onSelect={async (newRelayType) => {
              await updateRelayTypeHandler(2, newRelayType);
            }}
          />
        </div>
        <div className="flex items-center gap-x-0.5">
          <label htmlFor="relay-id" className={labelStyle}>
            장치3
          </label>
          <RelaySelect
            selectedRelay={relay3?.relayType}
            onSelect={async (newRelayType) => {
              await updateRelayTypeHandler(3, newRelayType);
            }}
          />
        </div>
        <div className="flex items-center gap-x-0.5">
          <label htmlFor="relay-id" className={labelStyle}>
            장치4
          </label>
          <RelaySelect
            selectedRelay={relay4?.relayType}
            onSelect={async (newRelayType) => {
              await updateRelayTypeHandler(4, newRelayType);
            }}
          />
        </div>
        <div className="flex items-center gap-x-0.5">
          <label htmlFor="relay-id" className={labelStyle}>
            장치5
          </label>
          <RelaySelect
            selectedRelay={relay5?.relayType}
            onSelect={async (newRelayType) => {
              await updateRelayTypeHandler(5, newRelayType);
            }}
          />
        </div>
        <div className="flex items-center gap-x-0.5">
          <label htmlFor="relay-id" className={labelStyle}>
            장치6
          </label>
          <RelaySelect
            selectedRelay={relay6?.relayType}
            onSelect={async (newRelayType) => {
              await updateRelayTypeHandler(6, newRelayType);
            }}
          />
        </div>
      </div>
    </div>
  );
}

// style
const labelStyle = clsx('text-sm text-subText min-w-[60px] whitespace-nowrap');
const buttonStyle = clsx(
  'border-subLine border rounded-md px-2.5 py-3 flex-1',
  'focus:outline-none',
  'font-bold text-sm',
);
