import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';

// css 기본 설정
import 'setting/font.css';
import 'setting/layout.css';
import 'css/index.css';
import 'css/slider.css';
import 'react-spring-bottom-sheet/dist/style.css';
import 'react-tooltip/dist/react-tooltip.css'; // tooltip css
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// 서비스 워커 등록: 새로고침 없이 업데이트 반영
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload(); // 자동으로 페이지 새로고침하여 최신 버전 반영
    }
  },
});
