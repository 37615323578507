import useTranslate from 'hooks/useTranslate';
import React, { useEffect, useState } from 'react';

const PWAInstallPrompt = () => {
  const t = useTranslate();
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [showInstallButton, setShowInstallButton] = useState(false);

  // ✅ 현재 기기가 모바일인지 확인하는 함수
  const isMobile = () => window.matchMedia('(max-width: 768px)').matches;

  // ✅ PWA가 이미 설치된 경우 확인
  const isPWAInstalled = () =>
    window.matchMedia('(display-mode: standalone)').matches;

  // ✅ 취소한 날짜를 `localStorage`에서 가져와서 30일이 지났는지 확인
  const isDismissedRecently = () => {
    const dismissedDate = localStorage.getItem('pwa-dismissed-date');
    if (!dismissedDate) return false;

    const currentTime = new Date().getTime();
    const dismissedTime = parseInt(dismissedDate, 10);
    const daysSinceDismissed =
      (currentTime - dismissedTime) / (1000 * 60 * 60 * 24);

    return daysSinceDismissed < 30; // 30일 이내면 true (안보이게 설정)
  };

  useEffect(() => {
    if (isDismissedRecently() || isPWAInstalled()) return; // ✅ 최근 취소했거나 PWA 설치 상태면 안내창 숨김

    const handleBeforeInstallPrompt = (event: any) => {
      event.preventDefault(); // 기본 동작 막기
      setDeferredPrompt(event);

      // ✅ 모바일 환경에서만 설치 버튼 표시
      if (isMobile()) {
        setShowInstallButton(true);
      }
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt,
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // PWA 설치 창 표시

      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('사용자가 PWA 설치를 수락함.');
        } else {
          console.log('사용자가 PWA 설치를 거부함.');
        }
        setDeferredPrompt(null);
        setShowInstallButton(false);
      });
    }
  };

  // ✅ "취소" 버튼 클릭 시 30일 동안 다시 표시되지 않도록 설정
  const handleCancelClick = () => {
    localStorage.setItem('pwa-dismissed-date', new Date().getTime().toString());
    setShowInstallButton(false);
  };

  if (!showInstallButton) return null;

  return (
    <div className="fixed z-50 p-4 text-center text-white rounded-lg shadow-lg bg-mainGreen bottom-5 left-5 right-5">
      <p>{t('pwa.install_message')}</p>
      <div className="flex justify-center gap-2 mt-2">
        <button
          className="px-4 py-2 bg-white rounded-lg text-mainGreen"
          onClick={handleInstallClick}
        >
          {t('pwa.install_button')}
        </button>
        <button
          className="px-4 py-2 text-white rounded-lg bg-subText"
          onClick={handleCancelClick}
        >
          {t('pwa.install_cancel')}
        </button>
      </div>
    </div>
  );
};

export default PWAInstallPrompt;
