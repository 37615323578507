import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Initializing from 'components/fallback/Initializing';
import { Suspense } from 'react';
import Routing from 'Routing';
import { reactQueryOption } from 'setting/reactQueryOption';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import clsx from 'clsx';
import useInit from 'hooks/useInit';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MasterFarmPage from 'pages/master/Page';
import MasterUserPage from 'pages/master/MasterUserPage';
import withMasterAuth from 'hoc/withMasterAuth';
import PWAInstallPrompt from 'components/prompt/PWAInstallPrompt';

// 마스터 권한이 필요한 페이지들을 HOC로 감싸기
const ProtectedMasterFarmPage = withMasterAuth(MasterFarmPage);
const ProtectedMasterUserPage = withMasterAuth(MasterUserPage);
/**React query */
const queryClient = new QueryClient({
  defaultOptions: reactQueryOption,
});

function App() {
  const { userName, isEnglish, isVietnamese } = useInit();

  return (
    <div
      className={clsx({
        'font-noto': !isVietnamese,
        '-tracking-[0.05em]': isEnglish,
        '-tracking-[0.07em] font-openSans': isVietnamese,
      })}
    >
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<Initializing />}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Routing userName={userName} />} />
              <Route path="/master" element={<ProtectedMasterFarmPage />} />
              <Route
                path="/master/farm/*"
                element={<ProtectedMasterFarmPage />}
              />
              <Route
                path="/master/user/*"
                element={<ProtectedMasterUserPage />}
              />
            </Routes>
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} />
        </Suspense>
      </QueryClientProvider>
      <PWAInstallPrompt />
      {/* 모바일뿐만 아니라 PC 버전에서도 설치 문구가 나오는 문제 */}
    </div>
  );
}

export default App;
